import React, { useEffect } from "react";
import Search from "../../components-pl/home/search";
import { isEmpty } from "lodash";
import Layout from "../../components-pl/layout";
// import Carousel  from "../../components-pl/home/carousel";
import SEO from "../../components-pl/seo";
import { sanitize } from "../../utils/functions";
import Link from "gatsby-link";

const ArchivePage = (props) => {
  const {
    pageContext: {
      categories,
      category,
      category: { name, seo, uri },
      postSearchData: { products, options },
    },
  } = props;

  useEffect(() => {
    if (props.pageContext.category.translations[0].link) {
      document.getElementById("lang-switch").href =
        props.pageContext.category.translations[0].link;
    }
  });

  let plCats = [];

  //console.log(categories)

  categories.nodes.forEach((item, i) => {
    //console.log(item.language.code === "PL")
    if (item.language.code === "PL") {
      plCats.push(item);
    }
  });
  //console.log(plCats)
  let filterCats = { nodes: plCats };
  return (
    <Layout>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={name}
            seoData={seo}
            uri={uri}
            header={{ siteTitle: " Shop" }}
            ogimage={props.pageContext.category?.image}
          />
          <div
            key={category.id}
            className="row cat-slajd"
            style={{
              display: "flex",
              flexWrap: "wrap",
              minWidth: "100%",
              marginLeft: "0",
              alignItems: "center",
              backgroundColor: "#E8D3D0",
            }}
          >
            <div
              className="carousel-info col"
              style={{ minHeight: "250px", textAlign: "center" }}
            >
              <div className="container">
                <div
                  className="carousal-info-inner"
                  style={{ textAlign: "left" }}
                >
                  <h1 className="spec-h">{category.name}</h1>
                  <div
                    className="text-small"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(category.description),
                    }}
                  />
                  <br />
                  <Link
                    to={category.uri + "#archive"}
                    tabIndex="-1"
                    className="btn btn-outline-dark"
                  >
                    Odkryj...
                  </Link>
                </div>
              </div>
            </div>

            <div className="carousel-img-wrap col">
              {!isEmpty(category.image) &&
              !isEmpty(category.image.sourceUrl) ? (
                <img
                  className="cat-img"
                  src={category.image.sourceUrl}
                  alt={
                    !isEmpty(category.image.altText)
                      ? category.image.altText
                      : category.name
                  }
                />
              ) : null}
            </div>
          </div>
          <Search
            products={products}
            initialProducts={category.products.nodes}
            engine={options}
            category={category}
            categories={filterCats}
          />

          <p
            className="pt-5 pb-5"
            style={{
              textAlign: "center",
              backgroundColor: "#FCFAF6",
              marginBottom: "0",
            }}
          >
            <Link to="/" className="btn btn-outline-dark mush">
              wróć do sklepu
            </Link>
          </p>
        </>
      ) : (
        <div>Coś poszło nie tak...</div>
      )}
    </Layout>
  );
};
export default ArchivePage;
